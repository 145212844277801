<template>
  <h1>페이지를 찾을 수 없습니다.</h1>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped></style>
